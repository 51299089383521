<template>
  <default-layout>
    <v-container id="referral-program-edit" fluid tag="section">
      <referral-program-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const ReferralProgramForm = () =>
  import('@/components/registration-management/referral-program/form.vue');

export default {
  name: 'project-edit',
  components: {
    DefaultLayout,
    ReferralProgramForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({}),
  },
  async created() {
    await this.$store.dispatch('referralProgram/form/restoreInitialState');
    this.$store.commit('referralProgram/form/SET_FORM_TYPE', 'EDIT');
    this.$store.commit('referralProgram/form/SET_UUID', this.$route.params.uuid);
    this.$store.dispatch('referralProgram/form/fetchBaseData');
  },
};
</script>
